import router from '@/router/index'
import Axios from "@/utility/axios";
import axios from "axios";
import Vue from "vue";

const showError = (commit, err) => {
    commit('setErrorMessage', err.response.data.message)
    commit('setErrorDebug', err.response.data.debugMessage)
    commit('setError', true)
}

// INFO
// mark unmark
export function onMarked({commit, state, dispatch}, {item, refresh}) {
    if (item.info.marked) {
        Axios.get('media-file/unmark', `/media-file/${item.id}/unmark`).then(response => {
            if (refresh) dispatch('getFiles')
        }).catch(err => {
            showError(commit, err)
        });
        /*
        this._vm.axios.get('/media-file/' + item.id + '/unmark').then(response => {
            if (refresh) dispatch('getFiles')
        }).catch(err => {
            showError(commit, err)
        })
         */
        item.info.marked = false
    } else {
        Axios.get('media-file/mark', `/media-file/${item.id}/mark`).catch(err => {
            showError(commit, err)
        });

        /*
        this._vm.axios.get('/media-file/' + item.id + '/mark').catch(err => {
            showError(commit, err)
        })
         */

        item.info.marked = true
    }
}

// delete and restore
export async function deleteFile({commit, dispatch}, id) {
    return Axios.get('media-file/mark-delete', `/media-file/${id}/mark-delete`).then(response => {
        dispatch('getFiles')
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.get('media-file/' + id + '/mark-delete').then(response => {
        dispatch('getFiles')
        return response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

export async function permanentDeleteFile({commit, dispatch}, id) {
    return Axios.get('media-file/delete', `/media-file/${id}/delete`).then(response => {
        dispatch('getFiles')
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.get('media-file/' + id + '/delete').then(response => {
        dispatch('getFiles')
        return response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

export async function restoreFile({dispatch, commit, state}, id) {
    Axios.get('media-file/restore', `/media-file/${id}/restore`).then(response => {
        dispatch('getFiles')
        state.selected = []
    });

    /*
    this._vm.axios.get('media-file/' + id + '/restore').then(response => {
        dispatch('getFiles')
        state.selected = []
    })
     */
}

//archive/ unarchive
export async function addToArchive({commit, dispatch}, id) {
    Axios.get('media-file/archive', `/media-file/${id}/archive`).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('media-file/' + id + '/archive').then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    })
     */
}

export async function restoreFromArchive({commit, dispatch}, id) {
    Axios.get('media-file/unarchive', `/media-file/${id}/unarchive`).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('media-file/' + id + '/unarchive').then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    })
     */
}

//channels
export async function addChannel({commit, dispatch}, {mediaFileId, channelId}) {
    Axios.get('media-file/channel', `/media-file/channel?mediaFileId=${mediaFileId}&channelId=${channelId}`).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('media-file/channel', {params: {mediaFileId, channelId}}).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    })    
     */
}

export async function removeChannel({commit, dispatch}, {mediaFileId, channelId}) {
    Axios.get('media-file/channel/remove', `/media-file/channel/remove?mediaFileId=${mediaFileId}&channelId=${channelId}`).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('media-file/channel/remove', {params: {mediaFileId, channelId}}).then(response => {
        dispatch('getFiles')
    }).catch(err => {
        showError(commit, err)
    })    
     */
}

export async function sync({commit, dispatch, state}, id) {
    return Axios.get('media-file/sync', `media-file/${id}/sync`).then(response => {
        dispatch('getFiles')
        state.syncRefresh = new Date().getTime()
        state.syncing = state.syncing.filter(sId => {
            if (sId !== id) return sId
        })
        state.syncError = state.syncError.filter(sId => {
            if (sId !== id) return sId
        })
        return true
    }).catch((err) => {
        state.syncing = state.syncing.filter(sId => {
            if (sId !== id) return sId
        })
        state.syncError.push(id)
        showError(commit, err)
        return false
    });

    /*
    return this._vm.axios.get(`media-file/${id}/sync`,).then(response => {
        dispatch('getFiles')
        state.syncRefresh = new Date().getTime()
        state.syncing = state.syncing.filter(sId => {
            if (sId !== id) return sId
        })
        state.syncError = state.syncError.filter(sId => {
            if (sId !== id) return sId
        })
        return true
    }).catch((err) => {
        state.syncing = state.syncing.filter(sId => {
            if (sId !== id) return sId
        })
        state.syncError.push(id)
        showError(commit, err)
        return false
    })    
     */
}

// FILTERS
export async function loadFiltersItems({commit}, projectId) {
    Axios.post('filter/options', `/filter/options?projectId=${projectId}`,
        ['info.type',
            'info.channels',
            'metadata.dc.subject',
            'metadata.dc.creator',
            'metadata.dc.rights.value',
            'metadata.xmpRights.UsageTerms.value']
    ).then(response => {
        commit('setFiltersItems', response.data)
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.post('/filter/options?projectId=' + projectId,
        ['info.type',
            'info.channels',
            'metadata.dc.subject',
            'metadata.dc.creator',
            'metadata.dc.rights.value',
            'metadata.xmpRights.UsageTerms.value']).then(response => {
        commit('setFiltersItems', response.data)
    }).catch(err => {
        showError(commit, err)
    })    
     */
}

export async function loadDistinctItems({commit, state}, projectId) {
    const uniqueDistinctItems = [...new Set(state.distincts)]

    return Axios.post('filter/options/distinct', `/filter/options?projectId=${projectId}`,
        uniqueDistinctItems.map(item => {
            return 'metadata.' + item
        })
    ).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('/filter/options?projectId=' + projectId, uniqueDistinctItems.map(item => {
        return 'metadata.' + item
    })).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    })    
     */
}

export function saveFilter({commit}, filter) {
    Axios.post('filter/save', `/filter`, filter).then(response => {
        commit('addFilter', {...response.data})
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.post('/filter', filter)
        .then(response => {
            commit('addFilter', {...response.data})
        }).catch(err => {
        showError(commit, err)
    })    
     */
}

export function loadFilters({commit}) {
    Axios.get('filter', '/filter').then((response) => {
        commit('setFilters', response.data)
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('/filter').then((response) => {
        commit('setFilters', response.data)
    }).catch(err => {
        showError(commit, err)
    })    
     */
}

export function removeFilter({commit, dispatch}, filter) {
    Axios.delete(`/filter/${filter.id}`).then((response) => {
        dispatch('loadFilters')
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.delete('/filter/' + filter.id)
        .then((response) => {
            dispatch('loadFilters')
        }).catch(err => {
        showError(commit, err)
    })    
     */
}

export function setFiltersById({state, commit, getters}, id) {
    const filter = getters.getFilterByIndex(id)
    commit('setFilterKeywords', filter.filters.keywords)
    commit('setFilterTypes', filter.filters.type)
    commit('setFilterChannels', filter.filters.channel)
    state.rightsFilter = filter.filters.rights
    state.creatorFilter = filter.filters.creator
    state.usageTermsFilter = filter.filters.usageTerms
}

// CONFIGURATION
export async function loadConfiguration({commit, state}) {
    return Axios.get('settings/load', '/user/settings/config').then((response) => {
        commit('setConfig', response.data)
    });

    /*
    return this._vm.axios.get('/user/settings/config').then(response => {
        commit('setConfig', response.data)
    })    
     */
}

// recursive for config parse
export async function parseFields({dispatch, state}, {fields, names, currName}) {
    if (names) {
        fields.forEach(field => {
            if (field.items === null) field.items = undefined
            if (field.formType === null) field.formType = undefined
            field.indexName = currName + '.' + field.name
            if (field.formType === 'selection' || field.formType === 'dropdown') {
                if (!field.items || !field.items.length) state.distincts.push(field.type !== "ALT_LANG_TEXT" ? field.indexName : field.indexName + '.value')
            }
            if (field.fields.length) {
                let arr = []
                names.push({
                    label: Object.create(field.label),
                    priority: field.priority,
                    name: field.name,
                    indexName: field.indexName,
                    names: arr,
                    enabled: field.enabled
                })
                dispatch('parseFields', {fields: field.fields, names: arr, currName: field.indexName})
            } else {
                names.push({
                    label: Object.create(field.label),
                    priority: field.priority,
                    name: field.name,
                    indexName: field.indexName,
                    enabled: field.enabled
                })
                if (field.type === "TEXT") field.value = ''
                if (field.type === "TEXT_BAG") field.value = []
                if (field.type === "TEXT_SEQ") field.value = []
                if (field.type === "OBJECT") field.value = {}
                if (field.type === "OBJECT_SEQ") field.value = []
                if (field.type === "OBJECT_BAG") field.value = []
                if (field.type === "ALT_LANG_TEXT") field.value = ''
            }
        })
    }
}

export async function configParse({state, dispatch}, config) {
    state.basic = []
    state.distincts = []
    const names = []
    for (const group of config) {
        let arr = [];
        names.push({
            label: Object.create(group.label),
            name: group.name,
            priority: group.priority,
            indexName: group.name,
            names: arr,
            enabled: group.enabled
        })
        await dispatch('parseFields', {fields: group.fields, names: arr, currName: group.name})
    }
    state.names = names.length > 10 ? [state.basicNames, ...names] : names
    return names
}

export async function uploadUserConfig({commit, state}, payload) {
    return Axios.post('user/settings', '/user/settings/config', payload.form).then(response => {
        state.imageConfig = payload
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('user/settings/config', payload.form).then(response => {
        state.imageConfig = payload
    }).catch(err => {
        showError(commit, err)
    })
     */
}

// COLUMNS
export async function loadUserColumns({commit, state}) {
    return Axios.get('user/settings/column', '/user/settings/column').then((response) => {
        state.columns.forEach(column => {
            if (response.data.selectedColumns) {
                if (response.data.selectedColumns.find(selected => column.value === selected)) {
                    column.selected = true;
                }
            }
        });
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.get('user/settings/column')
        .then((response) => {
            state.columns.forEach(column => {
                if (response.data.selectedColumns) {
                    if (response.data.selectedColumns.find(selected => column.value === selected)) {
                        column.selected = true
                    }
                }
            })
        }).catch(err => {
            showError(commit, err)
        })
     */
}

export async function uploadColumns({commit}, columns) {
    return Axios.post('user/settings/column/save', '/user/settings/column', JSON.stringify({selectedColumns: columns}), {
        headers: {
            'Content-Type': 'application/json'
        }
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('user/settings/column', JSON.stringify({selectedColumns: columns}),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch(err => {
        showError(commit, err)
    })
     */
}

export async function parseToColumns({dispatch}, {object, names}) {
    if (object.type === 'OBJECT_SEQ') {
        object.fields.forEach(field => {
            let arr = field.indexName.split('.')
            arr[arr.length - 1] = '0.' + arr[arr.length - 1]
            field.indexName = arr.join('.')
        })
    }
    if (object.fields.length) {
        object.fields.forEach(field => {
            dispatch('parseToColumns', {object: field, names})
        })
    } else {
        names.push({label: object.label, name: object.indexName, type: object.type})
    }
}

export async function loadColumns({commit, dispatch, state}) {
    await dispatch('configParse', state.imageConfig.form);

    let names = []
    state.imageConfig.form.forEach(group => {
        dispatch('parseToColumns', {object: group, names})
    })
    const result = names.map(name => {
        return {
            text: name.label,
            value: 'metadata.' + name.name,
            divider: true,
            sortable: false,
            selected: false,
            type: name.type
        }
    })
    state.columns = [{
        text: {'en-EN': 'actions', 'de-DE': 'Aktionen'},
        value: 'info.actions',
        divider: true,
        actions: true,
        sortable: false
    },
        {
            text: {'en-EN': 'preview', 'de-DE': 'Vorschau'},
            value: 'thumbnail',
            divider: true,
            selected: true,
            sortable: false
        },
        {
            text: {'en-EN': 'filename', 'de-DE': 'Dateiname'},
            value: 'info.fileName',
            divider: true,
            selected: true,
            sortable: true
        },
        {
            text: {'en-EN': 'upload date', 'de-DE': 'Upload-Datum'},
            value: 'info.uploadDate',
            divider: true,
            selected: true,
            sortable: true
        },
        {text: {'en-EN': 'Type', 'de-DE': ''}, value: 'info.type', divider: true, selected: false},
        {text: {'en-EN': 'Channels', 'de-DE': ''}, value: 'info.channels', divider: true, selected: false}]
    await commit('addColumns', result)
    return true
}

// FILES CONTROLS
// Downloading
export function downloadItems({commit, dispatch}, item) {
    return Axios.get('download', `/download/${item.id}`, {
        responseType: "blob",
        params: {
            height: item.height || null,
            width: item.width || null,
            profile: item.profile || null
        }
    }).then(response => {
        let blob = new Blob([response.data], {type: "application/jpg"})
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(blob);
        a.setAttribute("download", item.info.fileName);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }).catch(err => {
        err.response.data.text().then(text => {
            text = JSON.parse(text)
            commit('setErrorMessage', text.message)
            commit('setErrorDebug', text.debugMessage)
            commit('setError', true)
        })
    });

    /*
    return this._vm.axios.get('/download/' + item.id,
        {
            responseType: "blob",
            params: {
                height: item.height || null,
                width: item.width || null,
                profile: item.profile || null
            }
        }).then(response => {
        let blob = new Blob([response.data], {type: "application/jpg"})
        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);
        a.href = window.URL.createObjectURL(blob);
        a.setAttribute("download", item.info.fileName);
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }).catch(err => {
        err.response.data.text().then(text => {
            text = JSON.parse(text)
            commit('setErrorMessage', text.message)
            commit('setErrorDebug', text.debugMessage)
            commit('setError', true)
        })
    })
     */
}

export function downloadFrom({commit}, payload) {
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = typeof payload === 'string' ? payload : window.URL.createObjectURL(payload);
    a.setAttribute("download", 'iptc.json');
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
}

// uploading/ updating(edit)
export function upload({state, commit, dispatch}, {formData, uploading, goBack}) {
    return Axios.post(
        'file/upload',
        `/media-file${uploading ? '' : '/update'}` + (state.uploadStrategy ? `?duplicateStrategy=${state.uploadStrategy}` : ''),
        formData,
        {
            headers: {
                'Content-Type': uploading ? 'multipart/form-data' : 'application/json'
            }
        }).then(async response => {
        await dispatch('getFiles')
        if (goBack) {
            router.back()
            commit('setSuccess', true)
        }
        state.uploadStrategy = ''
        return response.data
    }).catch(err => {
        showError(commit, err)
        throw err
    });

    /*
    return this._vm.axios.post(url + (state.uploadStrategy ? `?duplicateStrategy=${state.uploadStrategy}` : ''), formData, {
        headers: {
            'Content-Type': uploading ? 'multipart/form-data' : 'application/json'
        }
    })
        .then(async response => {
            await dispatch('getFiles')
            if (goBack) {
                router.back()
                commit('setSuccess', true)
            }
            state.uploadStrategy = ''
            return response.data
        })
        .catch(err => {
            showError(commit, err)
            throw err
        })
     */
}

// Checking for file, to choose update strategy
export async function isFileExist({commit, state}, {file, projectId}) {
    return Axios.post('media-file/exists', `/media-file/checkImageExists?projectId=${projectId}`, file).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('/media-file/checkImageExists?projectId=' + projectId, file).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

// Get metadata on uploading
export async function getMetadata({commit, state}, payload) {
    const file = new FormData()
    file.append('file', payload)

    return Axios.post('download/metadata', '/download/metadata', file, {headers: {'Content-Type': 'multipart/form-data'}}).then(response => {
        state.currentMetadata = response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('download/metadata', file, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(response => {
            state.currentMetadata = response.data
        }).catch(err => {
            showError(commit, err)
        })
     */
}

export async function parseFileMetadata({state, dispatch}, {data, imageData}) {
    if (data) {
        for (const group of imageData.form) {
            await dispatch('groupParser', {group, data})
        }
    }
}

export async function groupParser({dispatch}, {group: object, data}) {
    for (const field of object.fields) {
        if (!field.fields.length) {
            switch (field.type) {
                case "TEXT":
                    field.value = await dispatch('index', {obj: data, is: field.indexName})
                    break;

                case "OBJECT_SEQ":
                    field.value = await dispatch('index', {obj: data, is: field.indexName + '.0'})
                    break;

                case "TEXT_SEQ":
                    field.value = await dispatch('index', {obj: data, is: field.indexName})
                    break;

                case "TEXT_BAG":
                    let bag = await dispatch('index', {obj: data, is: field.indexName})
                    if (Array.isArray(bag)) {
                        field.value = bag
                    } else if (bag) field.value = [bag]
                    else field.value = []

                    break;

                case "ALT_LANG_TEXT":
                    let lang = await dispatch('index', {obj: data, is: field.indexName})
                    if (!lang) {
                        field.value = ''
                    } else if (Array.isArray(lang)) {
                        field.value = lang[0]?.value ?? ''
                    } else field.value = lang.value
                    break;
            }
        } else {
            switch (field.type) {
                case "OBJECT_BAG":
                    let bag = await dispatch('index', {obj: data, is: field.indexName})
                    if (!bag) {
                        await dispatch('index', {obj: data, is: field.indexName, value: {}})
                        await dispatch('groupParser', {group: field, data})
                    } else if (Array.isArray(bag)) {
                        await dispatch('index', {obj: data, is: field.indexName, value: bag[0]})
                        await dispatch('groupParser', {group: field, data})
                    } else await dispatch('groupParser', {group: field, data})
                    break;
                case "OBJECT":
                    await dispatch('groupParser', {group: field, data})
                    break;
                case "OBJECT_SEQ":
                    let seq = await dispatch('index', {obj: data, is: field.indexName})
                    if (Array.isArray(seq)) {
                        await dispatch('index', {obj: data, is: field.indexName, value: seq[0]})
                        await dispatch('groupParser', {group: field, data})
                    } else await dispatch('groupParser', {group: field, data})
                    break;
            }
        }
    }
}

// Work with object by string index
export async function index({dispatch}, {obj, is, value}) {
    if (typeof is == 'string')
        return await dispatch('index', {obj, is: is.split('.'), value});
    else if (is.length == 1 && value !== undefined) {
        if (obj[is[0]] === undefined) {
            obj[is[0]] = {}
        }
        obj[is[0]] = value
        return obj[is[0]];
    } else if (is.length == 0)
        return obj;
    else {
        if (obj && obj[is[0]] === undefined) {
            if (value)
                obj[is[0]] = {}
            else return
        }
        if (obj)
            return await dispatch('index', {obj: obj[is[0]], is: is.slice(1), value})
    }
}

// Getting files for data-table
export async function getFiles({commit, state, dispatch}, reset = false) {
    if (router.currentRoute.name !== 'Selected') {
        state.filesLoading = true
        if (reset)
            state.files = [];

        const projectId = state.currentRequest.filter['info.projectId'];

        // new paging object
        const pagePayload = {
            filter: {
                has: {
                    items: {
                        "info.projectId": state.currentRequest.filter["info.projectId"] ?? false,
                        "info.archived": state.currentRequest.filter["info.archived"] ?? false,
                        "info.deleted": state.currentRequest.filter["info.deleted"] ?? false,
                        "info.marked": state.currentRequest.filter["info.marked"] ?? false,
                    }
                }
            },
            page: state.currentRequest.page,
            size: state.currentRequest.size
        };

        if (state.currentRequest.search) {
            pagePayload.filter.search = {
                items: {
                    ...state.currentRequest.search,
                }
            }
        }

        if (state.currentRequest.filter) {
            pagePayload.filter.contains = {
                items: {}
            };

            for (const key of Object.keys(state.currentRequest.filter)) {
                const list = state.currentRequest.filter[key];
                if (Array.isArray(list) && list.length > 0) {
                    pagePayload.filter.contains.items[key] = list;
                }
            }
        }

        await Axios.post('media-file/page', '/media-file/page', JSON.stringify(pagePayload), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            commit('setFiles', response.data.items)
            commit('setTotal', response.data.total)
            await dispatch('loadFiltersItems', projectId)
            state.filesLoading = false
        }).catch(err => {
            state.filesLoading = false
        });

        /*
        await this._vm.axios.post('/media-file/page', JSON.stringify(state.currentRequest), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            commit('setFiles', response.data.items)
            commit('setTotal', response.data.total)
            await dispatch('loadFiltersItems', projectId)
            state.filesLoading = false
        }).catch(err => {
            state.filesLoading = false
        })
         */
    }
}

export function getFile({commit}, mediaFileId) {
    return Axios.get('media-file/file', `/media-file/${mediaFileId}`).then(async response => {
        return response.data;
    }).catch(err => {
        return null;
    });
}

// Loading preview for edit page
export async function loadPreview({commit, state}, id) {
    state.base64Preview = ''
    Axios.get('image/preview', `/image/${id}/preview?height=500`).then((response) => {
        state.base64Preview = response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    this._vm.axios.get('/image/' + id + '/preview?height=500')
        .then((response) => {
            state.base64Preview = response.data
        }).catch(err => {
        showError(commit, err)
    })
     */
}

// Archive deeplink
export async function getDownloadLink({state, commit}, payload) {
    if (state.selectedC2Files.length) {
        payload.cockpitFiles = state.selectedC2Files
    }

    return Axios.post('download/link', '/download/deeplink', payload).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.post('download/deeplink', payload).then(response => {
        return response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

// Get all deep-links
export async function getDownloads({commit, state}, payload) {
    return Axios.get('download/downloads', `download/deeplink/${payload}`).then(response => {
        commit('setDeepLinks', response.data)
        return response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.get(`download/deeplink/${payload}`).then(response => {
        commit('setDeepLinks', response.data)
        return response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

export async function removeDownloadLink({state, commit, dispatch}, {id, projectId}) {
    return Axios.delete(`/download/deeplink?zipId=${id}`).then(async (response) => {
        await dispatch('getDownloads', projectId)
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.delete(`download/deeplink`, {params: {zipId: id}}).then(async (response) => {
        await dispatch('getDownloads', projectId)
    }).catch(err => {
        showError(commit, err)
    })
     */
}

// PROJECTS
export async function loadProjects({commit, state}) {
    return Axios.get('project', '/project').then(response => {
        state.projects = response.data
    }).catch(err => {
        showError(commit, err)
    });

    /*
    return this._vm.axios.get('/project').then(response => {
        state.projects = response.data
    }).catch(err => {
        showError(commit, err)
    })
     */
}

// CHANNELS
export async function loadChannels({commit, state}, projectId) {
    const {systemId} = state.projects.find(project => project.id === projectId) || {systemId: undefined}
    if (systemId) {
        Axios.get('channels', `/channel?projectId=${projectId}&systemId=${systemId}`).then(response => {
            state.channels = response.data
        }).catch(err => {
            showError(commit, err)
        });

        /*
        this._vm.axios.get('/channel', {params: {projectId: systemId}}).then(response => {
            state.channels = response.data
        }).catch(err => {
            showError(commit, err)
        })
         */
    }
}

//LOGIN
export async function login({state, dispatch}, formData) {
    formData.append('grant_type', 'password')
    return axios.post(process.oauthServer + '/oauth/token', formData, {
        headers: {
            'Content-Type': 'application/form-data'
        },
        auth: {
            username: process.env.VUE_APP_OAUTH_CLIENT_USERNAME,
            password: process.env.VUE_APP_OAUTH_CLIENT_PASSWORD
        }
    }).then(async response => {
        await localStorage.setItem('auth', response?.data['access_token'])
        state.loggedIn = true;

        await dispatch('getCurrentUser')
        await router.push('/projects')
        return false
    }).catch(err => {
        state.loggedIn = false;
        return true
    })
}

export async function getCurrentUser({commit}) {
    if (localStorage.getItem('auth')) {
        return axios.get(process.oauthServer + '/user/current', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('auth')
            }
        }).then(response => {
            localStorage.setItem('name', response.data.name)
        }).catch(err => {
            showError(commit, err)
        })
    }
}

export async function fetchEntities({commit, state}, {search, size, id}) {
    return await Axios.get('entities ', '/cockpit/entities', {
        params: {
            projectIdentifier: state.projects.find(project => project.id === id)?.identifier,
            search,
            size
        }
    }).then(response => {
        return response.data
    })
}

export async function fetchEntityFiles({commit, state}, id) {
    if (id) {
        return await axios.get('/cockpit/media-file/' + id).then(response => {
            return response.data
        })
    }
}

export async function logOut({state}) {
    state.loggedIn = false;
    localStorage.removeItem('auth');
    localStorage.removeItem('name');

    if (router.currentRoute.name !== 'Login') {
        await router.push('/login')
    }
}

//Browser check
export async function browserCheck() {
    //Check if browser is IE
    if (this._vm.$browserDetect.isIE) {
        await router.push('/browserError')
        return false
    }
//Check if browser is Chrome
    else if (this._vm.$browserDetect.isChrome) {
        return true
    }
//Check if browser is Firefox
    else if (this._vm.$browserDetect.isFirefox) {
        return true
    }
//Check if browser is Safari
    else if (this._vm.$browserDetect.isSafari) {
        return true
    }
//Check if browser is Opera
    else if (this._vm.$browserDetect.isOpera) {
        return true
    }
    return true
}



